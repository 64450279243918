
//action types
export const GET_ACCESS_LIST = 'GET_ACCESS_LIST';
export const ADD_ACCESS = 'ADD_ACCESS';
export const UPDATE_ACCESS = 'UPDATE_ACCESS';
export const DELETE_ACCESS = 'DELETE_ACCESS';
export const GET_ACCESS_DETAIL = 'GET_ACCESS_DETAIL';


export function getAccessList(data, callback) {
    return {
      type: GET_ACCESS_LIST,
      payload : {data, callback},
    };
}

export function getAccessDetail(data, callback) {
  return {
    type: GET_ACCESS_DETAIL,
    payload : {data, callback},
  };
}

export function addAccess(data, callback) {
  return {
    type: ADD_ACCESS,
    payload : {data, callback},
  };
}

export function updateAccess(data, callback) {
  return {
    type: UPDATE_ACCESS,
    payload : {data, callback},
  };
}

export function deleteAccess(data, callback) {
  return {
    type: DELETE_ACCESS,
    payload : {data, callback},
  };
}
