import React, { useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
import { TRANSACTION, PAGE_LIMIT } from 'helpers/viewContent';
import {getList_} from 'store/services/transactionService';
import {  FORMAT_TEXT } from "helpers/common";

//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import ReactPagination from "components/shared/ReactPagination";

const Listing = ({dispatch, itemsCountPerPage, totalItemsCount }) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [listing, setListing] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  async function getData() {
    try {
      let query = {
        page: activePage - 1,
        size: PAGE_LIMIT,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      setListing(res?.data)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, [activePage]);

  const handleSearchChange = async (event) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);

    // If the search bar is empty, fetch the listing data again
    if (inputValue === '') {
      await getData();
    } else {
      // Filter the listing based on the entered search value for both applicantName and email (assign user)
      const filteredListing = listing.filter(
        (item) =>
          item?.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
          item?.email?.toLowerCase().includes(inputValue.toLowerCase())
      );

      setListing(filteredListing);
    }
  };

  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

    return(
        <>
            <Helmet title={TRANSACTION.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                <i className="mdi mdi-chart-bar menu-icon"></i>
                </span>  {TRANSACTION.CURRENT_MODULE}
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.transaction}>{TRANSACTION.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Listing</li>
                </ol>
              </nav>
            </div>
            <div className="row">
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="card-title">Listing</h4>
                      </div>
                      <div className="col-md-6">
                       <input className="form-control"  autoComplete="new-password" name="search" type="text"  placeholder="Search" 
                        pattern="[a-zA-Z0-9\s]+"
                        value={searchValue}
                        onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th> Sr No. </th>
                            <th> Invoice Name </th>
                            <th> Applicant Name </th>
                            <th> User Name </th>
                            <th> Status </th>
                            <th> Download </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listing.length > 0 && listing.map((value, i) => {
                            return(
                              <tr key={i}>
                                <td>{i+1}.</td>
                                <td className="py-1">
                                  {FORMAT_TEXT(value?.invoiceName)}
                                  <div className="url-invoice">
                                   {/* Price: {value?.price !== 'null' ? value?.currency : ''} {value?.price}  */}
                                   Price: {value?.price} 
                                  </div>
                                </td>
                                <td> {value?.email ? FORMAT_TEXT(value?.email) : ''} </td>
                                <td>
                                {value?.name ? FORMAT_TEXT(value?.name) : ''}
                                </td>
                                <td><label class={`badge badge-${value?.status == 0 ? 'warning' : value?.status == 1 ? 'success' : 'danger'}`}>{value?.status == 1 ? 'Complete' : value?.status == 0 ? 'Pending' : 'Inactive'}</label></td>
                                <td>
                                  <a href={value?.invoiceUrl} target="_blank"><i className="mdi mdi-cloud-download" aria-hidden="true"></i></a>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    {listing.length > 0 && (
                      <ReactPagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
  return{
    itemsCountPerPage: state.Transaction.itemsCountPerPage,
    totalItemsCount: state.Transaction.totalItemsCount,
    loader: state.Transaction.loader,
  }
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(Listing);