
//action types
export const GET_DASHBOARD = 'GET_DASHBOARD';

//upload image
export function getDashboard(data, callback) {
    return {
      type: GET_DASHBOARD,
      payload : {data, callback},
    };
}
