import {AUTH_LOGIN, AUTH_CHECK,AUTH_LOGOUT } from 'store/actions/auth';
import Http from 'helpers/Http';

const initialState = {
    isAuthenticated: false,
    user: null,
    loader:false,
};

//login 
const authLogin = (state, payload) => {
  const { data } = payload;
  let inputString = data.jwt
  // let token = inputString.split('bearer=')[1].split(';')[0];
  localStorage.setItem('access_token', `${inputString}`);
  localStorage.setItem('user', JSON.stringify(data));
  Http.defaults.headers.common.Authorization = inputString;
  const stateObj = {
    ...state,
      isAuthenticated: true,
      user:data
    };
  return stateObj;
};


//check auth
const checkAuth = (state) => {
  const storedToken = localStorage.getItem('access_token');
  const storedUser = localStorage.getItem('user');

  const newState = {
    ...state,
    isAuthenticated: !!storedToken,
    user: storedUser ? JSON.parse(storedUser) : null,
  };

  if (newState.isAuthenticated) {
    Http.defaults.headers.common.Authorization = `${storedToken}`;
  }

  return newState;
};

//logout
const authLogout = (state) => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('user');
  const stateObj = {
    ...state,
    isAuthenticated: false,
    user: null,
  };
  return stateObj;
};


const Auth = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case AUTH_LOGIN:
          return authLogin(state, payload);
        case AUTH_CHECK:
          return checkAuth(state, payload);
        case AUTH_LOGOUT:
          return authLogout(state);
        default:
        return state;
    };
}
export default Auth;