
import { uploadImage_ } from 'store/services/fileService';
import * as actions from 'store/actions/index.js';

export async function UploadMediaFile(dispatch, file, documentName, applicationId) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('documentName', documentName);
        formData.append('applicationId', applicationId);

        dispatch(actions.persist_store({ loader: true }));
        await dispatch(uploadImage_(formData));
        dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
        console.log(err);
        dispatch(actions.persist_store({ loader: false }));
    }
}


