import React, { useState, useEffect, useRef, useCallback} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
import { APPLICATION } from 'helpers/viewContent';
import {  FORMAT_TEXT, DATE_FORMAT } from "helpers/common";
import { UploadMediaFile } from  "helpers/uploadFile"
//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import {getApplicationDetail_, updateApplicationStatusForAdmin_, UpdateAssignUser_} from 'store/services/applicationService';

const Detail = ({dispatch, match}) => {
    let statusKey = match.params.status;
    const [answers, setAnswers] = useState({})
    let statusVal =  parseInt(answers?.info?.status)
    const [selectedStatus, setSelectedStatus] = useState(answers?.info ? statusVal : -2);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [roleAdminId, setRoleAdminId] = useState(null);
    const [error, setError] = useState(null);
    const [isUpload, setIsUpload] = useState(false);
    const [isRole, setIsRole] = useState(false);
    const [isImageUpload, setIsImageUpload] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const fileInputRef = useRef(null);

    let editkey = match.params.id;
    //answers
    async function getData() {
        try{
           dispatch(actions.persist_store({ loader:true}));
           let res = await dispatch(getApplicationDetail_(editkey));
           setAnswers(res);
           dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
           dispatch(actions.persist_store({ loader:false}));
           console.log(err);
       }
    }
    //upload status
    const updateAdminStatusOnchange = async (e) => {
        const newStatus = e.target.value;
        setSelectedStatus(newStatus);
    };
    const updateAdminRoleOnchange = async (e) => {
        const newRole = e.target.value;
        setRoleAdminId(newRole);
    };
    const updateAdminStatus = async () => {
        try {
            if(selectedStatus){
                let obj = {
                    id :  match.params.id,
                    status : selectedStatus
                }
              // Call your API function with the new status
              setIsUpload(true)
              let res = await dispatch(updateApplicationStatusForAdmin_(obj));
              console.log('API call successful');
              setIsUpload(false)
              getData()
              // Optionally, you can fetch updated data or update state as needed
            }
        } catch (error) {
            setIsUpload(false)
            console.error('API call failed', error);
        }
    };
    useEffect(() => {
        getData();
    }, []);
    //image upload

    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        // Perform file type validation
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    
        // Check if file size is within the allowed limit (5MB)
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes

        if (file && allowedTypes.includes(file?.type) && file?.size <= maxSize) {
            setError(null);
    
            // Update selected file and show preview
            setSelectedFile(file);
            setSelectedType(file?.type);
    
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreviewImage(reader.result);
                };
                reader.readAsDataURL(file);
            } else {
                setPreviewImage(null);
            }
        } else {
            // Show an error if the file type is not allowed or exceeds size limit
            setSelectedFile(null);
            setPreviewImage(null);
    
            if (!allowedTypes.includes(file?.type)) {
                setError('Invalid file type. Please choose a valid image (JPEG, PNG, JPG, DOCX, PDF).');
            } else if (file?.size > maxSize) {
                setError('File size exceeds the limit of 5MB. Please choose a smaller file.');
            }
        }
    };
    
    const updateFileDocs = async (e) => {
        e.preventDefault();
        if(selectedFile){
            setIsImageUpload(true)
            await UploadMediaFile(dispatch, selectedFile, selectedFile.name, editkey);
            setSelectedFile(null)
            setPreviewImage(null)
            setError(null);
            setIsImageUpload(false)
            getData();
        }
    }
    const candelProfile = async () => {
        setSelectedFile(null)
        setPreviewImage(null)
    }
    const handleDragOver = (e) => {
        e.preventDefault();
      };
    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFileChange(file);
      };
    const handleClick = () => {
        fileInputRef.current.click();
      };
    
    const assignUserRole = async () => {
        try{
           let obj= {
            applicationId : editkey,
            adminId : roleAdminId
           }
           setIsRole(true)
            await dispatch(UpdateAssignUser_(obj));
            setIsRole(false)
        }catch(err){
            console.log(err)
            setIsRole(false)
        }
    }
    let encryptedSin;
    if (answers?.info?.sin && answers.info.sin.length > 9) {
      encryptedSin = encryptSIN(answers.info.sin);
    } else {
      encryptedSin = answers?.info?.sin || 'N/A';
    }
    const createdDate = answers?.info?.timestampCreated
    const updatedDate = answers?.info?.lastUpdatedTimestamp
    return(
        <>
            <Helmet title={APPLICATION.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                <i className="mdi mdi-chart-bar menu-icon"></i>
                </span>  {APPLICATION.CURRENT_MODULE}
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.application}>{APPLICATION.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Detail</li>
                </ol>
              </nav>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <h4 className="card-title">Detail</h4>
                                        </div>
                                        <div className="col-md-3">
                                            <p className="mb-0 font-weight-light float-right"><label class="badge badge-success">{statusVal == 0 ? 'Created' : statusVal == 1 ? 'Completed' : statusVal == 2 ? 'Payment done' : statusVal == 3 ? 'Document upload' : statusVal == 4 ? 'Admin approval' : statusVal == 5 ? 'Success' : statusVal == 6 ? 'Fail' : statusVal == 7 ? 'Edit access'  : statusVal == 8 ?  'Schedule Call' :statusVal == 9 ? 'E-Sign Request': 'N/A'}</label></p>
                                        </div>
                                    </div>
                                <div className="d-flex align-items-top">
                                    <div className="mb-0 flex-grow">
                                        <p className="mb-0 font-weight-light">Applicant Name : {answers?.info?.applicantName}</p>
                                        {(answers?.info?.linkedApplicationId || answers?.info?.linkedApplicationId != 0) &&
                                        <p className="mb-0 font-weight-light">Linked ApplicationId Id :{answers?.info?.linkedApplicationId}</p>
                                        }
                                        <p className="mb-0 font-weight-light">Assign User : {answers?.info?.adminName}</p>
                                        <p className="mb-0 font-weight-light">Application Creator  : {answers?.info?.email}</p>
                                        <p className="mb-0 font-weight-light">Filing Year : {answers?.info?.fiscal ? answers?.info?.fiscal : 'N/A'}</p>
                                        <p className="mb-0 font-weight-light">Sin Number: {encryptedSin}</p>
                                        <p className="mb-0 font-weight-light">Created: {answers?.info?.timestampCreated ? DATE_FORMAT(createdDate) : 'N/A'}</p>
                                        <p className="mb-0 font-weight-light">Updated: {answers?.info?.lastUpdatedTimestamp ? DATE_FORMAT(updatedDate) : 'N/A'}</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                                <div className="card">
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h4 className="card-title">Update Application Status</h4>
                                        <div class="add-items d-flex">
                                            <select className="form-select" name="status_application" onChange={(e) => updateAdminStatusOnchange(e)}
                                            value={statusVal}
                                            >
                                                <option value={0} selected={statusVal == 0 ? 'selected' : ''}>Initial Or Default </option>
                                                <option value={1} selected={statusVal == 1 ? 'selected' : ''}>Application Completed</option>
                                                <option value={2} selected={statusVal == 2 ? 'selected' : ''}>Payment Done</option>
                                                <option value={3} selected={statusVal == 3 ? 'selected' : ''}>In Review</option>
                                                <option value={4} selected={statusVal == 4 ? 'selected' : ''}>Application Approved</option>
                                                <option value={5} selected={statusVal == 5 ? 'selected' : ''}>Application Filling Success</option>
                                                <option value={6} selected={statusVal == 6 ? 'selected' : ''}>Application Filling  Fail </option>
                                                <option value={7} selected={statusVal == 7 ? 'selected' : ''}>Application Editable </option>
                                                <option value={8} selected={statusVal == 8 ? 'selected' : ''}>Schedule Call</option>
                                                <option value={9} selected={statusVal == 9 ? 'selected' : ''}>E-Sign Request</option>
                                            </select>
                                            <button class="add btn btn-gradient-primary font-weight-bold todo-list-add-btn" id="add-task" disabled={isUpload ? true : false}  onClick={() => updateAdminStatus()}>{isUpload ? 'Wait..' : <i className="mdi mdi-checkbox-multiple-marked-circle-outline"></i>}</button>
                                            
                                        </div>
                                        </div>
                                        {answers?.availableUsers?.length > 0 &&
                                        <div className="col-md-6">
                                            <h4 className="card-title">Ticket assigning</h4>
                                            <div class="add-items d-flex">
                                                <select className="form-select" onChange={(e) => updateAdminRoleOnchange(e)}>
                                                    <option value="0" disabled>Users </option>
                                                    {answers?.availableUsers?.length > 0 && answers?.availableUsers.map((val) =>  {
                                                       if(val.email !== '' && val.email){
                                                        return(
                                                        <option value={val?.id} selected={val.id == answers.info?.adminId ? 'selected' : ''}>{val.email}</option>
                                                        )
                                                        }
                                                    })}
                                                </select>
                                                <button class="add btn btn-gradient-primary font-weight-bold todo-list-add-btn" id="add-task" disabled={isRole ? true : false} onClick={() => assignUserRole()}>{isRole ? 'Wait..' : <i className="mdi mdi-checkbox-multiple-marked-circle-outline"></i>}</button>
                                            </div>
                                        </div>
                                        }
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={updateFileDocs}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="card-title">Upload Documents</h4>
                                            <div className=""
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                onClick={handleClick}
                                            >
                                            <div className="form-group">
                                                <input type="file" class="form-control file-upload-info" disabled="" placeholder="Upload Image" style={{ display: 'none' }} onChange={handleFileChange} ref={fileInputRef} />
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            </div>
                                            {error && <p className="text-danger mt-2">{error}</p>}
                                        </div>
                                        <button type="submit" disabled={selectedFile == null ? true : false}className="btn btn-gradient-primary me-2">
                                            {isImageUpload ? 'Wait..' : 'Submit'}
                                        </button>
                                        <button type="submit" className="btn btn-light" onClick={() => candelProfile()}>
                                            Cancel
                                        </button>
                                    </div>
                                    {previewImage && (
                                        <div className="col-md-6">
                                            <h4 className="card-title">Preview :</h4>
                                            <div className="mt-2">
                                            {selectedFile.type.startsWith('image/') &&
                                                <>
                                                    <p>Image Preview:</p>
                                                    <img src={previewImage} alt="Preview" style={{ width: '150px', height: '150px' }} />
                                                </>
                                            }
                                            </div>
                                        </div>
                                    )}
                                    {(selectedFile?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                    <>
                                    <div className="col-md-6">
                                    <h4 className="card-title">Preview :</h4>
                                        <div className="mt-2">
                                        {selectedFile.name}
                                        </div>
                                    </div>
                                    </>
                                    }
                                    {(selectedFile?.type === 'application/pdf') &&
                                    <>
                                    <div className="col-md-6">
                                    <h4 className="card-title">Preview :</h4>
                                        <div className="mt-2">
                                        {selectedFile.name}
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                            </form>
                            {answers?.adminDocuments?.length > 0 &&
                                <div class="row mt-3">
                                    <div class="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                <th> Sr No. </th>
                                                <th> Docx </th>
                                                <th> Doc Source </th>
                                                <th> Description </th>
                                                <th> Download </th>
                                                {/* <th> Action </th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {answers?.adminDocuments?.length > 0 && answers?.adminDocuments.map((docs, j) => {
                                                    return(
                                                        <tr key={j}>
                                                            <td>{j+1}.</td>
                                                            <td className="py-1">
                                                            {FORMAT_TEXT(docs.name)}
                                                            <div className="url-invoice">
                                                                URL: <a href={docs?.url} target="_blank" rel="noopener noreferrer"><p>{FORMAT_TEXT(docs?.url)}</p></a>
                                                            </div>
                                                            </td>
                                                            <td className="py-1">
                                                            {FORMAT_TEXT(docs.docSource)}
                                                            </td>
                                                            <td>
                                                            <div className="col-sm-6 col-md-4 col-lg-3">
                                                                {FORMAT_TEXT(docs.description)}
                                                            </div>
                                                            </td>
                                                            {/* <td>
                                                            <Link to={`${Path.documentview}/${docs.applicationId}/${docs.id}/${statusKey}`}><label class="badge badge-info" style={{cursor:'pointer'}} title="View">View</label></Link>
                                                            </td> */}
                                                            <td>
                                                            <a href={docs?.url} target="_blank"><i className="mdi mdi-cloud-download" aria-hidden="true"></i></a>
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {answers?.userResponse?.length &&
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card-body">
                                <h4 className="card-title">List of questions</h4>
                                {answers?.userResponse?.length > 0 && answers?.userResponse?.map((ans, i) => {
                                // Check if ans?.answer exists and is a non-empty string
                                // const answerLines = ans?.answer && typeof ans.answer === 'string' && ans.answer.trim().length > 0
                                //     ? ans.answer.trim().split(',').map((line, j) => line.trim())
                                //     : [ans?.answer]; // If ans?.answer is not a string or empty, keep it as is
                                if(ans.answer !== ''){
                                const answerLines = ans?.answer.split(',').map((line, j) => line.trim());
                                let inputString = ans?.answer;

                                // Split by "|" to get individual segments
                                let segments = inputString.split('|');

                                // Initialize an array to store the results
                                let outputArray = [];

                                // Process each segment
                                for (let segment of segments) {
                                    // Split by ";" to extract information
                                    let segmentData = segment.split(';');
                                    // Create an object with the desired properties
                                    let segmentObject = {
                                        'landlord_name': segmentData[0],
                                        'lived': segmentData[3],
                                        'rent': segmentData[2],
                                        'address': segmentData[1],
                                    };
                                    if(segment !== ''){
                                        // Push the object to the output array
                                        outputArray.push(segmentObject);
                                    }

                                }

                                return (
                                        <div className="d-flex mt-5 align-items-top" key={i}>
                                            <div className="mb-0 flex-grow">
                                                <h5 className="me-2 mb-2">{i + 1}. {ans.question}</h5>
                                                {/* Map over the lines to display them on separate lines */}
                                                {ans.type == 5 && answerLines.map((line, k) => (
                                                    <p key={k} className="mb-0 font-weight-light">Response: {line}</p>
                                                ))}
                                                {ans.type != 5 && ans.type != 10 &&
                                                <p  className="mb-0 font-weight-light">Response: {ans.answer}</p>
                                                }
                                                {ans.type == 10 &&
                                                <>
                                                {outputArray.length > 0 && outputArray.map((val, k) => {
                                                    return(
                                                        <>
                                                        <p  className="mb-0 font-weight-light">Landlord Name : {val.landlord_name}</p>
                                                        <p  className="mb-0 font-weight-light">Month Lived : {val.lived}</p>
                                                        <p  className="mb-0 font-weight-light">Rent Payed: {val.rent}</p>
                                                        <p  className="mb-0 font-weight-light">Address : {val.address}</p>
                                                        <br />
                                                        </>
                                                    )
                                                })}
                                                </>
                                                }
                                            </div>
                                        </div>
                                    );
                                }
                                })}

                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            }
            {answers?.userDocuments?.length > 0 &&
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card-body">
                                    <h4 className="card-title">Documents List</h4>
                                    <div class="row mt-3">
                                        <div class="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                    <th> Sr No. </th>
                                                    <th> Docx </th>
                                                    <th> Doc Source </th>
                                                    <th> Description </th>
                                                    <th> Download </th>
                                                    {/* <th> Action </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {answers?.userDocuments?.length > 0 && answers?.userDocuments.map((docs, j) => {
                                                        return(
                                                            <tr key={j}>
                                                                <td>{j+1}.</td>
                                                                <td className="py-1">
                                                                {FORMAT_TEXT(docs.name)}
                                                                <div className="url-invoice">
                                                                    URL: <a href={docs?.url} target="_blank" rel="noopener noreferrer"><p>{FORMAT_TEXT(docs?.url)}</p></a>
                                                                </div>
                                                                </td>
                                                                <td className="py-1">
                                                                {FORMAT_TEXT(docs.docSource)}
                                                                </td>
                                                                <td>
                                                                <div className="col-sm-6 col-md-4 col-lg-3">
                                                                    {FORMAT_TEXT(docs.description)}
                                                                </div>
                                                                </td>
                                                                {/* <td>
                                                                <Link to={`${Path.documentview}/${docs.applicationId}/${docs.id}/${statusKey}`}><label class="badge badge-info" style={{cursor:'pointer'}} title="View">View</label></Link>
                                                                </td> */}
                                                                <td>
                                                                <a href={docs?.url} target="_blank"><i className="mdi mdi-cloud-download" aria-hidden="true"></i></a>
                                                                </td>
                                                                
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>

    )
}

function encryptSIN(data) {
    const key1 = "ne"; // Your hardcoded key
     const key2 = "ly"; // Your hardcoded key
     const key3 = "ck"; // Your hardcoded key
     const key4 = "en"; // Your hardcoded key

     const pos1 = 4;
     const pos2 = 7;
     const pos3 = 2;
     const pos4 = 10;

     // Convert data to Base64
     data = data.substring(0, pos4) + data.substring(pos4 + key4.length);
     console.log(data);
     data = data.substring(0, pos3) + data.substring(pos3 + key3.length);
     console.log(data);
     data = data.substring(0, pos2) + data.substring(pos2 + key2.length);
     console.log(data);

     data = data.substring(0, pos1) + data.substring(pos1 + key1.length);
     console.log(data);

     // Decode Base64 and UTF-8
     const decodedString = atob(data);
     return decodedString;
}
/*
String decryptData(String data) {
    String key1 = "ne"; // Your hardcoded key
    String key2 = "ly"; // Your hardcoded key
    String key3 = "ck"; // Your hardcoded key
    String key4 = "en"; // Your hardcoded key
    int pos1 = 4;
    int pos2 = 7;
    int pos3 = 2;
    int pos4 = 10;

    // Convert data to Base64
    data = data.substring(0, pos4) + data.substring(pos4 + key4.length);
    print(data);
    data = data.substring(0, pos3) + data.substring(pos3 + key3.length);
    print(data);
    data = data.substring(0, pos2) + data.substring(pos2 + key2.length);
    print(data);
    data = data.substring(0, pos1) + data.substring(pos1 + key1.length);
    print(data);
    String decodedString = utf8.decode(base64.decode(data));
    return decodedString;
  }
*/


const mapStateToProps = (state) => {
    return{
    }
  };
  
  function mapDispatchToProps(dispatch) {
    return { dispatch };
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Detail);
