import React, { useState, useEffect, useCallback } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link,  useHistory } from 'react-router-dom';
import { SYSTMENACCESS } from 'helpers/viewContent';
import {addAccess_, getAccessDetail_, updateAccess_} from 'store/services/systemAccessService';
import * as Yup from 'yup';
//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';

const AddEdit = ({dispatch, match}) => {
    let editkey = match.params.id;
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        role: Yup.string().required('Role is required'),
        email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
        password: editkey
        ? Yup.string().min(6, 'Password must be at least 6 characters')
        : Yup.string(), // Password is not required if editKey exists
    
    });
    
    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };
   
    const history = useHistory();
    const [isShow, setIsShow] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [editData, setEditData] = useState({});
    const [fields, setfields] = useState({
        username: "",
        role: "",
        verified:1,
        email:"",
        password:""
    })

    const handleChange = useCallback(
        name => evt => {
          setfields(prevState => ({
            ...prevState,
            [name]: evt.target.value,
          }))
          setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )
    
    async function getData() {
        try{
           dispatch(actions.persist_store({ loader:true}));
           let res = await dispatch(getAccessDetail_(editkey));
           setEditData(res);
           let final_obj = {
            username : res.username,
            role : res?.roles?.[0].name,
            verified : res.verified,
            email : res.email,
        }
           setfields(final_obj)
           dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
           dispatch(actions.persist_store({ loader:false}));
           console.log(err);
       }
    }
    
  useEffect(() => {
    if(editkey){
        getData()
    }
  }, []);

    //submit setting data
  const handleSubmit = async (evt) => {
    try{
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        dispatch(actions.persist_store({ loader:true}));
        setIsShow(true)
        if(editkey){
            await dispatch(updateAccess_(fields));
        }else{
            await dispatch(addAccess_(fields));
        }
        setIsShow(false)
        dispatch(actions.persist_store({ loader:false}));
        history.push(Path.system_access)
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
        setIsShow(false)
        console.log(err);
    }
  }

    return(
        <>
            <Helmet title={SYSTMENACCESS.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                <i className="mdi mdi-contacts menu-icon"></i>
                </span>  {SYSTMENACCESS.CURRENT_MODULE}
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.system_access}>{SYSTMENACCESS.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Add/Edit</li>
                </ol>
              </nav>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Add/Edit</h4>
                            <form className="forms-sample" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label for="exampleInputName1">Name</label>
                                <input type="text" className="form-control" id="exampleInputName1" placeholder="Name" name="username" onChange={handleChange("username")}
                                value={fields.username}
                                />
                                {validationErrors.username && (
                                    <div className="text-danger">{validationErrors.username}</div>
                                )}
                            </div>
                            <div className="form-group">
                            <label for="exampleInputName1">Role</label>
                                <div className="col-md-12">
                                <select className="form-select" name="role" onChange={handleChange("role")}
                                value={fields.role}>
                                    <option value="ROLE_ADMIN" selected={editData.roles?.length > 0  && editData.roles[0].name === 'ROLE_ADMIN' ? 'selected' : ''}>Admin </option>
                                    <option value="ROLE_MODERATOR" selected={editData.roles?.length > 0  && editData.roles[0].name === 'ROLE_MODERATOR' ?  'selected' : ''}>Master Admin </option>
                                    <option value="ROLE_USER" selected={editData.roles?.length > 0  && editData.roles[0].name === 'ROLE_USER' ? 'selected' : ''}>User </option>
                                </select>
                                {validationErrors.role && (
                                    <div className="text-danger">{validationErrors.role}</div>
                                )}
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="exampleInputEmail3">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail3" placeholder="Email" name="email" onChange={handleChange("email")}
                                value={fields.email} />
                                {validationErrors.email && (
                                    <div className="text-danger">{validationErrors.email}</div>
                                )}
                            </div>
                            {!editkey &&
                            <div className="form-group">
                                <label for="exampleInputPassword4">Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword4" placeholder="Password"  name="password" onChange={handleChange("password")}
                                value={fields.password} />
                                {validationErrors.password && (
                                    <div className="text-danger">{validationErrors.password}</div>
                                )}
                            </div>
                            }
                            <div className="row">
                            <label for="exampleInputPassword4">Status</label>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="">
                                        <input type="radio" className="" value={1} name="verified" onChange={handleChange("verified")} checked={fields.verified == 1 ? true : false} /> Active 
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="">
                                        <input type="radio" className=""  value={-1} name="verified" onChange={handleChange("verified")} checked={fields.verified == -1 ? true : false}/> Inactive </label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" disabled={isShow ? true : false} className="btn btn-gradient-primary me-2">{isShow ? 'Wait..' : 'Submit'}</button>
                            <Link to={Path.system_access}><button className="btn btn-light">Back</button></Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
    return{
    }
  };
  function mapDispatchToProps(dispatch) {
    return { dispatch };
  }
  
  export default connect(
  mapStateToPros,
  mapDispatchToProps
  )(AddEdit);