import React, { useState, useCallback, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link, useHistory } from 'react-router-dom';
import { CMS } from 'helpers/viewContent';
import * as Yup from 'yup';
import {addCms_, viewCms_} from 'store/services/pagesService';

//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';

const CmsContent = ({dispatch, match}) => {
    let slugUrl = match?.params?.key
    const [fields, setfields] = useState({})

    async function getDataView() {
        try {
          dispatch(actions.persist_store({ loader: true }));
          let res = await dispatch(viewCms_(slugUrl));
          setfields(res)
          dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
          dispatch(actions.persist_store({ loader: false }));
          console.log(err);
        }
    }
    useEffect(() => {
        if(slugUrl){
            getDataView();
        }
    }, []);

    return(
        <>
            <Helmet title={CMS.CURRENT_VIEW} />
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="mb-0 flex-grow">
                                <h5 className="me-2 mb-2">{fields?.description}</h5>
                                <p className="mb-0 font-weight-light"><div dangerouslySetInnerHTML={{ __html: fields?.value }} /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
const mapStateToPros = (state) => {
    return{
    }
  };
  function mapDispatchToProps(dispatch) {
    return { dispatch };
  }
  
  export default connect(
  mapStateToPros,
  mapDispatchToProps
  )(CmsContent);