import React, { useState } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
import { DASHBOARD } from 'helpers/viewContent';
//countUp
import CountUp from 'react-countup';

const Dashboard = ({userProfile}) => {
    return(
        <>
        <Helmet title={DASHBOARD.CURRENT_MODULE} />
        <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                <i className="mdi mdi-home"></i>
                </span> Dashboard
            </h3>
        </div>
        {userProfile?.kpis?.length > 0 &&
            <div className="row">
                {userProfile?.kpis?.length > 0 && userProfile?.kpis?.map((item, i) => {
                    return(
                        <div className="col-md-4 stretch-card grid-margin" key={i}>
                            <div className="card bg-gradient-danger card-img-holder text-white">
                                <div className="card-body">
                                    <img src="../../assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                                    <h4 className="font-weight-normal mb-3">{item?.name}<i className="mdi mdi-chart-line mdi-24px float-right"></i>
                                    </h4>
                                    <h2 className="mb-5"><CountUp start={0} end={item.count ? item?.count : 0} duration={3}/></h2>

                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        }
        
        </>

    )
}
const mapStateToPros = (state) => {
    return{
        userProfile : state.Dashboard.dashboardProfile
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Dashboard);