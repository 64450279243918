
//action types
export const GET_LIST = 'GET_LIST';

//upload image
export function getList(data, callback) {
    return {
      type: GET_LIST,
      payload : {data, callback},
    };
}
