import { getData, postData } from 'helpers/Helper'
import { APP_PATH, APPLICATION_LIST, APPLICATION_QUESTION, APPLICATION_DOCUMENT, APPLICATION_STATUS, DELETE_APPLICATION, UPDATED_APLLICATION_ROLE, ASSIGN_ROLE_LIST, APPLICATION_DETAIL  } from './apiPath'
import { getApplication, getAnswersForApplication, getRequiredDocumentsApplication, updateApplicationStatusForAdmin, removeApplication, UpdateAssignUser, roleAssignUser, getApplicationDetail} from 'store/actions/application';

//get
export function getApplicationList(value, callback) {
  const { page, size, key,status } = value || {};
  let url = `${APP_PATH}${APPLICATION_LIST}`;
  if (page !== undefined && size !== undefined && key !== undefined && status !== undefined) {
    url += `?page=${page}&size=${size}&key=${key}&status=${status}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getApplication(data, callback));
      return data;
    });
}

//get
export function getApplicationDetail_(value, callback) {
  let url = `${APP_PATH}${APPLICATION_DETAIL}?applicationId=${value}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getApplicationDetail(data, callback));
      return data;
    });
}

export function getAnswersForApplicationList(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${APPLICATION_QUESTION}?applicationId=${value}&spouseOnly=0`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getAnswersForApplication(data, callback));
      return data;
  });
}

export function getRequiredDocumentsApplication_(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${APPLICATION_DOCUMENT}?applicationId=${value}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getRequiredDocumentsApplication(data, callback));
      return data;
    });
}
  
export function updateApplicationStatusForAdmin_(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${APPLICATION_STATUS}?applicationId=${value.id}&status=${value.status}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(updateApplicationStatusForAdmin(data, callback));
      return data;
    });
}
export function removeApplication_(value, callback) {
  let url = `${APP_PATH}${DELETE_APPLICATION}`;
  if (value !== undefined) {
    url += `?applicationId=${value}`;
  } 
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(removeApplication(data, callback));
      return data;
  });
}


export function roleAssignUser_(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${ASSIGN_ROLE_LIST}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(roleAssignUser(data, callback));
      return data;
  });
}


export function UpdateAssignUser_(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${UPDATED_APLLICATION_ROLE}?applicationId=${value.applicationId}&adminId=${value.adminId}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(UpdateAssignUser(data, callback));
      return data;
  });
}