import {getData, postData} from 'helpers/Helper'
import { APP_PATH, GET_ACCESS_LIST, ADD_ACCESS, UPDATE_ACCESS, DELETE_ACCESS, GET_ACCESS_DETAIL } from './apiPath'
import { getAccessList, addAccess, updateAccess, deleteAccess, getAccessDetail } from 'store/actions/systemAccess';
//upload image
export function getAccessList_(value, callback) {
    const { page, size } = value || {};
    let url = `${APP_PATH}${GET_ACCESS_LIST}`;
    if (page !== undefined && size !== undefined) {
      url += `?page=${page}&size=${size}`;
    } 
    return (dispatch) => getData(url, value)
      .then((data) => {
        dispatch(getAccessList(data, callback));
        return data;
   });
}

//add
export function addAccess_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${ADD_ACCESS}`, value)
    .then((data) => {
      dispatch(addAccess(data, callback));
      return data;
    });
}

export function updateAccess_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${UPDATE_ACCESS}`, value)
    .then((data) => {
      dispatch(updateAccess(data, callback));
      return data;
    });
}

export function deleteAccess_(value, callback) {
  let url = `${APP_PATH}${DELETE_ACCESS}`;
  if (value !== undefined) {
    url += `?id=${value}`;
  } 
  return (dispatch) => postData(url)
    .then((data) => {
      dispatch(deleteAccess(data, callback));
      return data;
    });
}
export function getAccessDetail_(value, callback) {
  let url = `${APP_PATH}${GET_ACCESS_DETAIL}`;
  if (value !== undefined) {
    url += `?id=${value}`;
  } 
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getAccessDetail(data, callback));
      return data;
    });
}

