import {getData} from 'helpers/Helper'
import { APP_PATH, GET_TRANSCATION_LIST} from './apiPath'
import { getList } from 'store/actions/transaction';
//upload image
export function getList_(value, callback) {
    const { page, size } = value || {};
    let url = `${APP_PATH}${GET_TRANSCATION_LIST}`;
    if (page !== undefined && size !== undefined) {
      url += `?page=${page}&size=${size}`;
    }
    return (dispatch) => getData(url, value)
      .then((data) => {
        dispatch(getList(data, callback));
        return data;
   });
}