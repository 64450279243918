import {getData} from 'helpers/Helper'
import { APP_PATH, GET_DASHBOARD_} from './apiPath'
import { getDashboard } from 'store/actions/dashboard';
//upload image
export function getDashboard_(value, callback) {
    return (dispatch) => getData(`${APP_PATH}${GET_DASHBOARD_}`, value)
      .then((data) => {
        dispatch(getDashboard(data, callback));
        return data;
   });
}