
import React, { lazy } from 'react';

//imported
import * as Path from './paths';
//auth
import SignIn from "components/views/admin/auth/Login"
//dashboard
import Dashboard from "components/views/admin/dashboard/Dashboard";
//application
import ApplicationList from "components/views/admin/application/Listing";
import ApplicationView from "components/views/admin/application/Detail";
import DocumentView from "components/views/admin/application/Documents";
//transaction
import TransactionList from "components/views/admin/transaction/Listing";
import TransactionView from "components/views/admin/transaction/Detail";
//system access
import SystemAccessList from "components/views/admin/system_access/Listing";
import SystemAccessAddEdit from "components/views/admin/system_access/AddEdit";
//cms
import CMSList from "components/views/admin/cms/Listing";
import CMSAddEdit from "components/views/admin/cms/AddEdit";
import CmsContent from "components/publicCMS/CmsContent";

//not found page
import NotFound from "components/NotFound";

const routes = [  
    /******** login routes ***********/
	{
		path: Path.login,
		exact: true,
		auth: false,
		fallback: true,
		// component: lazy(() => import('components/views/admin/modules/auth/Login'))
		component: SignIn

	},
    /******** dashboard ***********/
    {
		path: Path.dashboard,
		exact: true,
		auth: true,
		component: Dashboard
	},
	/******** application ***********/
	{
		path: Path.application,
		exact: true,
		auth: true,
		component: ApplicationList
	},
	{
		path: Path.application_view,
		exact: true,
		auth: true,
		component: ApplicationView
	},
	/******** document ***********/
	{
		path: Path.document_view,
		exact: true,
		auth: true,
		component: DocumentView
	},
	/******** transaction ***********/
	{
		path: Path.transaction,
		exact: true,
		auth: true,
		component: TransactionList
	},
	{
		path: Path.transaction_view,
		exact: true,
		auth: true,
		component: TransactionView
	},
	/******** system access ***********/
	{
		path: Path.system_access,
		exact: true,
		auth: true,
		component: SystemAccessList
	},
	{
		path: Path.system_access_add,
		exact: true,
		auth: true,
		component: SystemAccessAddEdit
	},
	{
		path: Path.system_accessadd,
		exact: true,
		auth: true,
		component: SystemAccessAddEdit
	},
	/******** cms ***********/
	{
		path: Path.cms,
		exact: true,
		auth: true,
		component: CMSList
	},
	{
		path: Path.cms_form,
		exact: true,
		auth: true,
		component: CMSAddEdit
	},
	{
		path: Path.cmsform,
		exact: true,
		auth: true,
		component: CMSAddEdit
	},
	{
		path: Path.cmsPage,
		exact: true,
		auth: false,
		component: CmsContent
	},
	{
		path: Path.cms_Page,
		exact: true,
		auth: false,
		component: CmsContent
	},

	/******** not found page ***********/
	{
		path: '',
		exact: false,
		component: NotFound
	},

];

export default routes;
