import {getData, postData} from 'helpers/Helper'
import { APP_PATH, GET_CMS_LIST, ADD_CMS, UPDATE_CMS, DELETE_CMS, VIEW_CMS_DETAIL } from './apiPath'
import { getCms, addCms, deleteCms, updateCms, viewCms} from 'store/actions/pages';
//upload image
export function getCms_(value, callback) {
    let url = `${APP_PATH}${GET_CMS_LIST}`;
    return (dispatch) => getData(url, value)
      .then((data) => {
        dispatch(getCms(data, callback));
        return data;
   });
}

//add
export function addCms_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${ADD_CMS}`, value)
    .then((data) => {
      dispatch(addCms(data, callback));
      return data;
    });
}

//view
export function viewCms_(value, callback) {
  let url = `${APP_PATH}${VIEW_CMS_DETAIL}?key=${value}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(viewCms(data, callback));
      return data;
 });
}

export function updateCms_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${UPDATE_CMS}`, value)
    .then((data) => {
      dispatch(updateCms(data, callback));
      return data;
    });
}

export function deleteCms_(value, callback) {
  let url = `${APP_PATH}${DELETE_CMS}`;
  if (value !== undefined) {
    url += `?id=${value}`;
  } 
  console.log(url)
  return (dispatch) => postData(url)
    .then((data) => {
      dispatch(deleteCms(data, callback));
      return data;
    });
}

