import {postMediaData} from 'helpers/Helper'
import { APP_PATH, UPLOAD_IMAGE_PATH, DELETE_IMAGE_PATH} from './apiPath'
import { uploadImage, deleteUploadImage,} from 'store/actions/file';
//upload image
export function uploadImage_(formData, callback) {
    return (dispatch) => postMediaData(`${APP_PATH}${UPLOAD_IMAGE_PATH}`, formData)
      .then((data) => {
        dispatch(uploadImage(data, callback));
        return data;
      });
  }
  
  //delete image
export function deleteUploadImage_(value, callback) {
    return (dispatch) => postMediaData(`${APP_PATH}${DELETE_IMAGE_PATH}`, value)
      .then((data) => {
        dispatch(deleteUploadImage(data, callback));
        return data;
    });
 }
