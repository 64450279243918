
//action types
export const GET_CMS = 'GET_CMS';
export const ADD_CMS = 'ADD_CMS';
export const UPDATE_CMS = 'UPDATE_CMS';
export const DELETE_CMS = 'DELETE_CMS';
export const VIEW_CMS = 'VIEW_CMS';

export function getCms(data, callback) {
    return {
      type: GET_CMS,
      payload : {data, callback},
    };
}

export function addCms(data, callback) {
  return {
    type: ADD_CMS,
    payload : {data, callback},
  };
}

export function updateCms(data, callback) {
  return {
    type: UPDATE_CMS,
    payload : {data, callback},
  };
}
export function viewCms(data, callback) {
  return {
    type: VIEW_CMS,
    payload : {data, callback},
  };
}
export function deleteCms(data, callback) {
  return {
    type: DELETE_CMS,
    payload : {data, callback},
  };
}
