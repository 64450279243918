//action types
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

//auth
export function authLogin(payload) {
  return {
    type: AUTH_LOGIN,
    payload,
  };
}

//check auth
export function authCheck() {
    return {
      type: AUTH_CHECK,
    };
}

//auth logout
export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  };
}


