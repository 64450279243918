
let mainPath = '/admin'

export const login = '/admin/login';
export const dashboard = `${mainPath}/dashboard`;
//application
export const application = `${mainPath}/application/listing`;
export const application_view = `${mainPath}/application/detail/:id/:status`;
export const applicationview = `${mainPath}/application/detail`;
//documents
export const document_view = `${mainPath}/application/document/:applicationId/:id/:status`;
export const documentview = `${mainPath}/application/document`;

//transaction
export const transaction = `${mainPath}/transaction/listing`;
export const transaction_view = `${mainPath}/transaction/detail`;
//cms
export const cms = `${mainPath}/content_page`;
export const cms_form = `${mainPath}/content_page/form`;
export const cmsform = `${mainPath}/content_page/form/:key`;
export const cms_Page = '/cms';
export const cmsPage = `/cms/:key`;
//system_access
export const system_access = `${mainPath}/system_access/listing`;
export const system_access_add = `${mainPath}/system_access/form`;
export const system_accessadd = `${mainPath}/system_access/form/:id`;

