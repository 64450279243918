import React, { useState, useCallback, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link, useHistory } from 'react-router-dom';
import { CMS } from 'helpers/viewContent';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as Yup from 'yup';
import {addCms_, viewCms_} from 'store/services/pagesService';

//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
const editorConfiguration = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'link', '|',
      'bulletedList', 'numberedList', '|',
      'blockQuote', 'insertTable', '|',
      'mediaEmbed', 'imageInsert', 'fileBrowser', 'exportPdf', 'exportWord', 'exportXml', '|',
      'undo', 'redo'
    ],
    sourceEditing: {
      preview: true, // Enable the preview feature
      highlightActiveLine: true, // Enable highlighting the line the cursor is on
      highlightMatches: true // Enable highlighting matching tag pairs
    }
  };
const AddEdit = ({dispatch, match}) => {
    const validationSchema = Yup.object().shape({
        description: Yup.string().required('Title is required'),
        value: Yup.string().required('Description is required'),
        key: Yup.string().required('Slug is required'),
    });
    let slugUrl = match?.params?.key

    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };
    const history = useHistory();
    const [isShow, setIsShow] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [fieldValue, setFieldValue] = useState(null);
    const [fields, setfields] = useState({
        value: "",
        key: "",
        description: '',
    })

    const handleChange = useCallback(
        name => evt => {
          setfields(prevState => ({
            ...prevState,
            [name]: evt.target.value,
          }))
          setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )

    //submit setting data
    const handleSubmit = async (evt) => {
        try{
        evt.preventDefault();
        const validation = await validateData(fields);
        if (validation.isValid) {
            let  obj = {
                ...fields,
                // value : fieldValue
            }
            setIsShow(true)
            dispatch(actions.persist_store({ loader:true}));
            setIsShow(false)
            await dispatch(addCms_(obj));
            dispatch(actions.persist_store({ loader:false}));
            history.push(Path.cms)
        } else {
            setValidationErrors(validation.errors);
        }
        } catch (err) {
            setIsShow(false)
            console.log(err);
        }
    }

    async function getDataView() {
        try {
          dispatch(actions.persist_store({ loader: true }));
          let res = await dispatch(viewCms_(slugUrl));
          setfields(res)
          setFieldValue(res?.description)
          dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
          dispatch(actions.persist_store({ loader: false }));
          console.log(err);
        }
    }
    useEffect(() => {
        if(slugUrl){
            getDataView();
        }
    }, []);

    return(
        <>
            <Helmet title={CMS.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                <i className="mdi mdi-table-large menu-icon"></i>
                </span>  {CMS.CURRENT_MODULE}
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.cms}>{CMS.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">{slugUrl ? 'Edit' : 'Add'}</li>
                </ol>
              </nav>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">{slugUrl ? 'Edit' : 'Add'} Content</h4>
                        <form className="forms-sample" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label for="exampleInputName1">Title</label>
                            <input type="text" className="form-control" id="exampleInputName1" placeholder="Title" name="description" onChange={handleChange("description")}
                                value={fields.description}
                            />
                            {validationErrors.description && (
                                <div className="text-danger">{validationErrors.description}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label for="exampleInputName1">Slug</label>
                            <div className="col-md-12">
                                <select className="form-select" name="key" onChange={handleChange("key")}
                                value={fields.key}>
                                    <option value="">Select </option>
                                    <option value="TERMS_CONDITION" selected={fields.key === 'TERMS_CONDITION' ? 'selected' : ''}>Terms & Conditions </option>
                                    <option value="ABOUT_US" selected={fields.key === 'ABOUT_US' ? 'selected' : ''}>About Us</option>
                                    <option value="PRIVACY_POLICY" selected={fields.key === 'PRIVACY_POLICY' ? 'selected' : ''}>Privacy Policy</option>
                                </select>
                                {validationErrors.key && (
                                    <div className="text-danger">{validationErrors.key}</div>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="exampleTextarea1">Description</label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={ editorConfiguration }
                                data={fields.value}
                                onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log( { event, editor, data } );
                                setFieldValue(editor.getData());
                                setfields({value: editor.getData() });
                                setValidationErrors({value: "" });
                                }}
                                
                                className="ckeditor-control ckeditor-custom-height"
                            />
                            {validationErrors.value && (
                                <div className="text-danger">{validationErrors.value}</div>
                            )}
                            {/* {fieldValue == null && (
                                <div className="text-danger">Description is required</div>
                            )} */}
                        </div>
                       
                        <button type="submit" className="btn btn-gradient-primary me-2">Submit</button>
                        <Link to={Path.cms}><button className="btn btn-light">Back</button></Link>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </>

    )
}
const mapStateToPros = (state) => {
    return{
    }
  };
  function mapDispatchToProps(dispatch) {
    return { dispatch };
  }
  
  export default connect(
  mapStateToPros,
  mapDispatchToProps
  )(AddEdit);