import React, { useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
import { DOCUMENTS } from 'helpers/viewContent';
import {  FORMAT_TEXT } from "helpers/common";
//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import {getRequiredDocumentsApplication_} from 'store/services/applicationService';

const Detail = ({dispatch, match}) => {
    let statusKey = match.params.status;
    let editkey = match.params.applicationId;
    let editkeyName = match.params.id;
    const [documents, setDocuments] = useState({})
    const [documentLinks, setDocumentLinks] = useState([])
    //documents
    async function getDocuments() {
        try{
           dispatch(actions.persist_store({ loader:true}));
           let res = await dispatch(getRequiredDocumentsApplication_(editkey));
           for(let val of res){
               if(editkeyName == val.id){
                setDocuments(val);
                setDocumentLinks(val.links)
            }
           }
           
           dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
           dispatch(actions.persist_store({ loader:false}));
           console.log(err);
       }
    }

    useEffect(() => {
        getDocuments();
    }, [editkey, editkeyName]);

    return(
        <>
            <Helmet title={DOCUMENTS.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                <i className="mdi mdi-chart-bar menu-icon"></i>
                </span>  {DOCUMENTS.CURRENT_MODULE} Documents
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.application}>{DOCUMENTS.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page"><Link to={`${Path.applicationview}/${editkey}/${statusKey}`}>Detail</Link></li>
                </ol>
              </nav>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card-body">
                                <h4 className="card-title">Documents Deatil</h4>
                                    <div className="d-flex align-items-top">
                                        <div className="mb-0 flex-grow">
                                            <p className="mb-0 font-weight-light">Document : {documents?.name}</p>
                                            <p className="mb-0 font-weight-light">Souce : {documents?.docSource}</p>
                                            <p className="mb-0 font-weight-light">Email : {documents?.email}</p>
                                            <p className="mb-0 font-weight-light">Description : {documents?.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card-body">
                                    <h4 className="card-title">Documents List</h4>
                                    <div class="row mt-3">
                                    <div class="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                <th> Sr No. </th>
                                                <th> Docx </th>
                                                <th> URL </th>
                                                <th> Download </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {documentLinks.length > 0 && documentLinks.map((links, j) => {
                                                    return(
                                                        <tr key={j}>
                                                            <td>#{j+1}.</td>
                                                            <td className="py-1">
                                                               {links.name ? FORMAT_TEXT(links.name) : '--'}
                                                            </td>
                                                            <td>
                                                            <div className="col-sm-6 col-md-4 col-lg-3">
                                                                <a href={links.url} target="_blank">{links.url ? FORMAT_TEXT(links.url): '---'}</a>
                                                            </div>
                                                            </td>
                                                            <td>
                                                            <a href={links.url} target="_blank"><i className="mdi mdi-cloud-download" aria-hidden="true"></i></a>
                                                            </td>
                                                            {/* <td>
                                                            <Link to={`${Path.applicationview}/${docs.applicationId}/${docs.name}/${statusKey}`} ><label class="badge badge-info" style={{cursor:'pointer'}} title="View">View</label></Link>
                                                            </td> */}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


const mapStateToProps = (state) => {
    return{
    }
  };
  
  function mapDispatchToProps(dispatch) {
    return { dispatch };
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Detail);
